import { useTheme } from "@emotion/react";
import { useResponsive } from "../../hooks/ResponsiveProvider";
import { Box,  Grid, Stack, Typography } from "@mui/material";
import CustomButton from "../../components/@extended/CustomButton";
import * as React from 'react';
import { useDispatch } from "react-redux";
import { useResendOTPMutation, useVerifyOTPMutation } from "../../store/slices/apiSlice";
import { openMessage } from "../../store/slices/showMessageSlice";

import OTPInputPannal from "./OTPInputPannal";

export default function MobileVarification({ userData, handleComplete,handleNext, handleBack, activeStep ,completedSteps }) {
  
  const { isSmallScreen } = useResponsive();
  const dispatch = useDispatch();

  const [verifyOTP, { error1 }] = useVerifyOTPMutation(); 
  const [resendOTP, { error2 }] = useResendOTPMutation();  

  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);
  const [genarateOtp, setGenarateOtp] = React.useState(false);

  const [otp, setOtp] = React.useState('');

  const onResendOTP = async () => {
    const result = await resendOTP({
      payload: {
        type: "PHONE_VERIFICATION"
      },
    });
    if (!result?.error) {
      openMessageNotification({
        message: "OTP Send Successfully",
        type: "success",
      });
    } else {
      openMessageNotification({
        message: result?.error?.data?.message,
        type: "error",
      });
    }
    if(!result?.error){
      setMinutes(1);
      setSeconds(59);
    }    
  };
  const onVerifyOTP = async () => {
    if(otp.length===6){
      const result = await verifyOTP({
        payload: {
          otp: otp,
          type: "PHONE_VERIFICATION"
        },
      });
      if (!result?.error) {
        handleComplete();
        openMessageNotification({
          message: "Mobile Number Verified Successfully",
          type: "success",
        });
      } else {
        openMessageNotification({
          message: result?.error?.data?.message,
          type: "error",
        });
      }
    }
    else{
      openMessageNotification({
        message: "Please enter the OTP to proceed",
        type: "error",
      });
    }
  };
  const openMessageNotification = (message) => {
    dispatch(openMessage({ message: message.message, messageSeverity: message.type }))
  };



  return (
    <Grid >
      <Grid item >
      <Box sx={{ paddingTop: isSmallScreen ? 8 : 4 }}>
      </Box>
          <Grid sx={{display:'flex',justifyContent:'center'}} container rowSpacing={3}>
            <Grid item xs={12} px={0}>
              <Typography  variant="body1" color="#5d5d5d" pb={1}>
              <span style={{fontWeight:600}}>Mobile Number :</span> {userData?.contactNumber}
              </Typography>
            </Grid>
            { 
              <Stack alignItems='flex-start'>
                <OTPInputPannal genarateOtp={genarateOtp} minutes={minutes} setMinutes={setMinutes} seconds={seconds} setSeconds={setSeconds} otp={otp} setOtp={setOtp} onResendOTP={onResendOTP}/>
              </Stack>
            }
          </Grid>
          {genarateOtp?
          <CustomButton 
            onClick={onVerifyOTP} 
            variant="contained" 
            sx={{ color: 'white',mt:2}}
          >Verify OTP
          </CustomButton>:
          <CustomButton 
            onClick={() => {setGenarateOtp(true);onResendOTP()}} 
            variant="contained" 
            sx={{ color: 'white' ,mt:2}}
          >Send OTP
          </CustomButton>
        }  
        </Grid>    
        <Grid item xs={12} sx={{ px: 0 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <CustomButton
            variant="outlined"

            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </CustomButton>
        </Box>

      </Grid>
    </Grid>
  )
}